import React from 'react'
import { Global, css } from '@emotion/react'
import { mq } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'

const styles = css`
  @media ${mq.with(breakpoints.xxl)} {
    .v-gt_sm {
      display: none;
    }

    .v-gt_md {
      display: none;
    }

    .v-gt_lg {
      display: none;
    }

    .v-xxl {
      display: none;
    }

    .v-xl {
      display: none;
    }

    .v-lg {
      display: none;
    }

    .v-md {
      display: none;
    }

    .v-sm {
      display: none;
    }

    .v-xs {
      display: none;
    }
  }

  @media ${mq.with(breakpoints.xl)} {
    .v-gt_sm {
      display: none;
    }

    .v-gt_md {
      display: none;
    }

    .v-gt_lg {
      display: none;
    }

    .v-xxl {
      display: none;
    }

    .v-xl {
      display: block;
    }

    .v-lg {
      display: none;
    }

    .v-md {
      display: none;
    }

    .v-gt_md {
      display: none;
    }

    .v-sm {
      display: none;
    }

    .v-xs {
      display: none;
    }
  }

  @media ${mq.with(breakpoints.lg)} {
    .v-gt_sm {
      display: none;
    }

    .v-gt_md {
      display: none;
    }

    .v-gt_lg {
      display: none;
    }

    .v-xxl {
      display: none;
    }

    .v-xl {
      display: none;
    }

    .v-lg {
      display: block;
    }

    .v-md {
      display: none;
    }

    .v-sm {
      display: none;
    }

    .v-xs {
      display: none;
    }
  }

  @media ${mq.with(breakpoints.md)} {
    .v-gt_sm {
      display: none;
    }

    .v-gt_md {
      display: none;
    }

    .v-gt_lg {
      display: none;
    }

    .v-xxl {
      display: none;
    }

    .v-xl {
      display: none;
    }

    .v-lg {
      display: none;
    }

    .v-md {
      display: block;
    }

    .v-sm {
      display: none;
    }

    .v-xs {
      display: none;
    }
  }

  @media ${mq.with(breakpoints.sm)} {
    .v-gt_sm {
      display: none;
    }

    .v-gt_md {
      display: none;
    }

    .v-gt_lg {
      display: none;
    }

    .v-xxl {
      display: none;
    }

    .v-xl {
      display: none;
    }

    .v-lg {
      display: none;
    }

    .v-md {
      display: none;
    }

    .v-sm {
      display: block;
    }

    .v-xs {
      display: block;
    }
  }

  @media ${mq.with(breakpoints.xs)} {
    .v-gt_sm {
      display: none;
    }

    .v-gt_md {
      display: none;
    }

    .v-gt_lg {
      display: none;
    }

    .v-xxl {
      display: none;
    }

    .v-xl {
      display: none;
    }

    .v-lg {
      display: none;
    }

    .v-md {
      display: none;
    }

    .v-sm {
      display: block;
    }

    .v-xs {
      display: block;
    }
  }

  @media ${mq.with(breakpoints.gt_lg)} {
    .v-gt_lg {
      display: block;
    }
  }

  @media ${mq.with(breakpoints.gt_md)} {
    .v-gt_md {
      display: block;
    }
  }

  @media ${mq.with(breakpoints.gt_sm)} {
    .v-gt_sm {
      display: block;
    }
  }
`

const Style = () => <Global styles={styles} />
export default Style

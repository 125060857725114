export interface Breakpoint {
  key: string
  min?: number
  max?: number
  query: string
}

const xxl: Breakpoint = {
  key: 'xxl',
  min: 1921,
  query: '(min-width: 1921px)'
}
const xl: Breakpoint = {
  key: 'xl',
  min: 1201,
  query: '(max-width: 1920px) and (min-width: 1201px)'
}
const gt_lg: Breakpoint = {
  key: 'gt_lg',
  min: 1201,
  query: '(min-width: 1201px)'
}
const lg: Breakpoint = {
  key: 'lg',
  max: 1200,
  min: 1024,
  query: '(max-width: 1200px) and (min-width: 1024px)'
}
const gt_md: Breakpoint = {
  key: 'gt_md',
  min: 1024,
  query: '(min-width: 1024px)'
}
const md: Breakpoint = {
  key: 'md',
  max: 1023,
  min: 769,
  query: '(max-width: 1023px) and (min-width: 769px)'
}
const gt_sm: Breakpoint = {
  key: 'gt_sm',
  min: 769,
  query: '(min-width: 769px)'
}
const sm: Breakpoint = {
  key: 'sm',
  max: 768,
  query: '(max-width: 768px)'
}
const xs: Breakpoint = {
  key: 'xs',
  max: 374,
  query: '(max-width: 374px)'
}

export default {
  xxl,
  xl,
  lg,
  gt_lg,
  md,
  gt_md,
  sm,
  xs,
  gt_sm
} as {
  [key: string]: Breakpoint
}

import { css } from '@emotion/react'
import { Breakpoint } from '@/utils/breakpoints'

function hexToRgb(hex: string) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (_m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1] || '0', 16),
        g: parseInt(result[2] || '0', 16),
        b: parseInt(result[3] || '0', 16)
      }
    : {
        r: 0,
        g: 0,
        b: 0
      }
}

export function rgba(hex: string, alpha: number) {
  const rgb = hexToRgb(hex)
  return `rgba(${rgb.r},${rgb.g},${rgb.b},${alpha})`
}

export function modifier(className: string) {
  return `m_${className}`
}

export function state(className: string) {
  return `s_${className}`
}

export const mq = {
  with: (breakpoint: Breakpoint) => {
    return breakpoint.query
  },
  and: (...args: Breakpoint[]) => {
    const queries = Array.from(args).map((breakpoint) => breakpoint.query)
    return `${queries.join(',')}`
  }
}

export function px(val: number) {
  return `${val}px`
}

export function matchMedia(mediaType: string) {
  return function (...args: Breakpoint[]) {
    return Array.from(args)
      .map((type) => type.key)
      .includes(mediaType)
  }
}

export function placeholder(color: string) {
  return css`
    &::placeholder {
      color: ${color};
    }

    &:placeholder-shown {
      color: ${color};
    }

    &::-webkit-input-placeholder {
      color: ${color};
    }

    &:-moz-placeholder {
      color: ${color};
      opacity: 1;
    }

    &::-moz-placeholder {
      color: ${color};
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: ${color};
    }
  `
}

export function scrollbar(color: string, rail = 'transparent') {
  return css`
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 4px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${color};
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0;
      background-color: ${rail};
      height: 1px;
    }
  `
}

export function truncate(str: string, n: number) {
  return str.length > n ? str.substr(0, n - 1) + '…' : str
}

export function aspectRatio(w: number, h: number, position = 'relative') {
  return css`
    position: ${position};
    &::after {
      content: '';
      display: block;
      padding-top: ${(h / w) * 100}%;
      width: 0;
    }
  `
}

export function aspectRatioChild() {
  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
}

export function nl2br(str: string) {
  str = str.replace(/\r\n/g, '<br />')
  str = str.replace(/(\n|\r)/g, '<br />')
  return str
}

export function rem(size: number) {
  const rem = size / 16
  return `${rem}rem`
}

export function shadeColor(color: string, percent: number) {
  const num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt

  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)}`
}

export default {
  rgba,
  modifier,
  state,
  mq,
  px,
  matchMedia,
  placeholder,
  truncate,
  scrollbar,
  aspectRatio,
  aspectRatioChild,
  nl2br,
  shadeColor
}

import React, { FC } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { layouts, breakpoints } from '@/utils/preset'
import { mq } from '@/utils/helpers'

interface Responsive {
  xxl?: number
  xl?: number
  lg?: number
  gt_lg?: number
  md?: number
  gt_md?: number
  sm?: number
  xs?: number
  gt_sm?: number
  default?: number
}

interface Props {
  type?: number
  responsive?: Responsive
  nested?: boolean
  [key: string]: any
}

const Container: FC<Props> = (props) => {
  return <Root {...props}>{props.children}</Root>
}

Container.propTypes = {
  type: PropTypes.oneOf([1, 2, 3]),
  responsive: PropTypes.any
}

Container.defaultProps = {
  nested: false
}

export default Container

function getMaxWidth(props: Props) {
  const type = props.type
  const responsive = props.responsive
  if (type) {
    return css`
      ${Object.keys(breakpoints)
        .filter((breakpoint) => {
          return !breakpoint.startsWith('gt_')
        })
        .map((breakpoint: string) => {
          return css`
            @media ${mq.with(breakpoints[breakpoint])} {
              max-width: ${layouts.width[breakpoint][type]};
            }
          `
        })};
    `
  }
  if (!responsive) {
    return css`
      ${Object.keys(breakpoints)
        .filter((breakpoint) => {
          return !breakpoint.startsWith('gt_')
        })
        .map((breakpoint: string) => {
          return css`
            @media ${mq.with(breakpoints[breakpoint])} {
              max-width: ${layouts.width[breakpoint][1]};
            }
          `
        })};
    `
  }
  const keys = Object.keys(responsive) as (keyof Responsive)[]
  return css`
    ${keys.map((breakpoint) => {
      const width = responsive[breakpoint] as number
      if (breakpoint === 'default') {
        return css`
          ${Object.keys(breakpoints)
            .filter((breakpoint) => {
              return !breakpoint.startsWith('gt_')
            })
            .map((breakpoint: string) => {
              return css`
                @media ${mq.with(breakpoints[breakpoint])} {
                  max-width: ${layouts.width[breakpoint][width]};
                }
              `
            })};
        `
      }
      return css`
        @media ${mq.with(breakpoints[breakpoint])} {
          max-width: ${layouts.width[breakpoint][width]};
        }
      `
    })}
  `
}

function getPadding(props: Props) {
  if (props.nested) return
  const type = props.type
  const responsive = props.responsive
  if (type) {
    return css`
      ${Object.keys(breakpoints)
        .filter((breakpoint) => {
          return !breakpoint.startsWith('gt_')
        })
        .map((breakpoint: string) => {
          return css`
            @media ${mq.with(breakpoints[breakpoint])} {
              padding: 0 ${layouts.pad[breakpoint]};
            }
          `
        })};
    `
  }
  if (!responsive) {
    return css`
      ${Object.keys(breakpoints)
        .filter((breakpoint) => {
          return !breakpoint.startsWith('gt_')
        })
        .map((breakpoint: string) => {
          return css`
            @media ${mq.with(breakpoints[breakpoint])} {
              padding: 0 ${layouts.pad[breakpoint]};
            }
          `
        })};
    `
  }
  const keys = Object.keys(responsive) as (keyof Responsive)[]
  return css`
    ${keys.map((breakpoint: string) => {
      if (breakpoint === 'default') {
        return css`
          ${Object.keys(breakpoints).map((breakpoint: string) => {
            return css`
              @media ${mq.with(breakpoints[breakpoint])} {
                padding: 0 ${layouts.pad[breakpoint]};
              }
            `
          })};
        `
      }
      return css`
        @media ${mq.with(breakpoints[breakpoint])} {
          padding: 0 ${layouts.pad[breakpoint]};
        }
      `
    })}
  `
}

const Root = styled.div<Props>`
  margin: 0 auto;
  box-sizing: content-box;
  ${getMaxWidth};
  ${getPadding};
`

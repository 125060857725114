export default {
  header: {
    base: 1100
  },
  floating: {
    base: 1000
  },
  progress: {
    base: 10000
  }
}

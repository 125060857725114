import React, { useContext, ComponentType, Context } from 'react'

interface P {
  [key: string]: any
}

const withContext = <Props extends P>(
  contextName: string,
  context: Context<any>,
  WrappedComponent: ComponentType<Props>
) =>
  function HOC(p: Props) {
    return (
      <WrappedComponent
        {...{
          [contextName]: useContext(context),
          ...p
        }}
      />
    )
  }

export default withContext

import React, { FC } from 'react'
import styled from '@emotion/styled'

interface Props {
  className?: string
  children: string
  [key: string]: any
}

const Icon: FC<Props> = ({ children, className }) => (
  <Root
    className={`icons icons-${children}${className ? ' ' + className : ''}`}
  ></Root>
)

const Root = styled.i``

export default Icon

import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import ThemeContext from '@/context/Theme'
import { Theme, color } from '@/utils/themes'
import { rem } from '@/utils/helpers'

interface Props {
  color?: string
  title?: string
  [key: string]: any
}

interface StyleProps extends Props {
  theme: Theme
}

const Header: FC<Props> = ({ title, ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root theme={theme} {...others}>
      <SVG
        width="119"
        height="40"
        viewBox="0 0 119 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title && <title>{title}</title>}
        <path
          d="M20.3286 18.4818L21.0158 15.0701H16.5075L14.631 22.0007C19.695 21.7424 21.6884 23.6822 20.9086 26.5188C20.314 28.6926 17.7406 29.6089 12.6863 27.9323L11.5215 31.4317C19.0955 33.9076 23.4674 31.807 24.8857 26.5188C25.9677 22.4735 24.5591 19.3006 20.3286 18.4964"
          fill="currentColor"
        />
        <path
          d="M42.6273 8.89485L35.3652 20.0512L39.1035 32.2895H44.0018L40.3269 20.0512L47.5402 8.89485H42.6273Z"
          fill="currentColor"
        />
        <path
          d="M70.764 15.7865H66.8259L64.8032 22.4784C60.5045 22.4784 58.1552 25.4027 57.5363 27.6934C56.9514 29.8818 57.5801 32.2895 61.1917 32.2895C65.7098 32.2895 67.1086 28.5707 67.6837 26.4165H71.3342L72.387 22.4784H68.7413L70.764 15.7865ZM62.2054 28.4976C61.4061 28.4976 61.2599 27.9518 61.3866 27.4741C61.6108 26.6358 62.4101 26.2361 63.9795 26.2361C63.6091 27.6203 63.3021 28.4976 62.2054 28.4976Z"
          fill="currentColor"
        />
        <path
          d="M59.3395 23.4775L62.7219 13.4519H71.0514L72.1091 9.51384H64.0281L64.7007 7.54478H60.7675L60.09 9.51384H57.1024L56.0496 13.4519H58.7838L55.4014 23.4775H59.3395Z"
          fill="currentColor"
        />
        <path
          d="M16.6592 11.4829L27.0162 11.478L27.874 7.5399L17.5219 7.54478L16.6592 11.4829Z"
          fill="currentColor"
        />
        <path
          d="M72.6016 13.4519H76.5348L77.5924 9.51382H73.6543L72.6016 13.4519Z"
          fill="currentColor"
        />
        <path
          d="M102.79 7.54477H98.852L97.7943 11.4829H92.9204L91.8628 15.421H96.7367L94.6994 23.0194C93.8903 26.046 92.9546 27.2109 89.4551 27.2109L88.4023 31.149C94.4898 31.149 97.0243 29.0337 98.852 22.2152L100.675 15.421H105.159L106.212 11.4829H101.728L102.79 7.54477Z"
          fill="currentColor"
        />
        <path
          d="M103.648 10.2449H105.905L106.68 7.54478H104.419L103.648 10.2449Z"
          fill="currentColor"
        />
        <path
          d="M107.883 7.54478L107.108 10.2449H109.404L110.179 7.54478H107.883Z"
          fill="currentColor"
        />
        <path
          d="M89.1973 11.4829H85.2592C85.2592 11.4829 80.9312 27.2012 80.1709 30.028H85.2934L86.0537 27.2109H84.8986C85.0009 26.8259 89.2168 11.4829 89.2168 11.4829"
          fill="currentColor"
        />
        <path
          d="M7.05252 1.52588e-05L0 39.9951H111.178L118.231 1.52588e-05H7.05252ZM109.068 37.4802H3.01694L9.1824 2.51493H115.233L109.068 37.4802Z"
          fill="currentColor"
        />
      </SVG>
    </Root>
  )
}

Header.defaultProps = {
  title: 'らくなげ',
  color: 'primary'
}

export default Header

const Root = styled.span<StyleProps>`
  color: ${color()};
  width: ${rem(119)};
  height: ${rem(40)};
`

const SVG = styled.svg`
  svg {
    width: 100%;
    height: 100%;
  }
`

import React, { FC, useState } from 'react'
import ThemeContext from '@/context/Theme'
import styled from '@emotion/styled'
import themes from '@/utils/themes'
import Header from '@/components/sections/Header'
import Copyright from '@/components/sections/Copyright'

const App: FC = (props) => {
  const [theme, setTheme] = useState(themes.default)
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Root id="top">
        <Header />
        {props.children}
        <Copyright />
      </Root>
    </ThemeContext.Provider>
  )
}

export default App

const Root = styled.div``

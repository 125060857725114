import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { css, SerializedStyles } from '@emotion/react'
import ThemeContext from '@/context/Theme'
import { mq, rem } from '@/utils/helpers'
import { breakpoints, fonts } from '@/utils/preset'
import { color, Theme, ColorParams } from '@/utils/themes'

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'overline'
  | 'button'
  | 'price'

interface Props {
  lang?: string
  variant: Variant
  component?: any
  color?: ColorParams
  [key: string]: any
}

interface Responsive {
  xxl?: Variant
  xl?: Variant
  lg?: Variant
  gt_lg?: Variant
  md?: Variant
  gt_md?: Variant
  sm?: Variant
  xs?: Variant
  gt_sm?: Variant
}
export interface TypographyProps extends Props {
  responsive?: Responsive
}

interface StyleProps extends TypographyProps {
  theme: Theme
}

interface Style {
  css: SerializedStyles
  component: keyof JSX.IntrinsicElements
}
type Styles = {
  [key in Variant]: Style
}

const Typography: FC<TypographyProps> = (props) => {
  const { theme } = useContext(ThemeContext)
  const Root = getComponent({
    ...props,
    theme
  })
  const rootProps = {
    ...props,
    theme
  }
  delete rootProps.component
  return <Root {...rootProps}>{props.children}</Root>
}

export default Typography

const getComponent = (props: StyleProps) => {
  const responsive = props.responsive
  if (!responsive) {
    const css = styles[props.variant].css
    const component = props.component || styles[props.variant].component
    return styled(Base)`
      ${css};
    `.withComponent(component)
  }
  const keys = Object.keys(responsive) as (keyof Responsive)[]
  const styledComponent = styled(Base)`
    ${styles[props.variant].css}
    ${keys.map((breakpoint) => {
      const variant = responsive[breakpoint] as Variant
      return css`
        @media ${mq.with(breakpoints[breakpoint])} {
          ${styles[variant].css}
        }
      `
    })}
  `
  const component = props.component || styles[props.variant].component
  return styledComponent.withComponent(component)
}

const Base = styled.div`
  color: ${(props: StyleProps) => {
    if (props.color) {
      return color(props.color)({ theme: props.theme })
    } else {
      return 'currentColor'
    }
  }};
  letter-spacing: 0.04em;
  overflow-wrap: break-word;
  font-family: ${({ lang = 'jp' }: Props) =>
    lang === 'jp' ? fonts.base : fonts.en};
`

const styles: Styles = {
  h1: {
    css: css`
      font-size: ${rem(48)};
      font-weight: bold;
      line-height: 1.3;
    `,
    component: 'h1'
  },
  h2: {
    css: css`
      font-size: ${rem(36)};
      font-weight: bold;
      line-height: 1.3;
    `,
    component: 'h2'
  },
  h3: {
    css: css`
      font-size: ${rem(24)};
      font-weight: bold;
      line-height: 1.3;
    `,
    component: 'h3'
  },
  h4: {
    css: css`
      font-size: ${rem(20)};
      font-weight: bold;
      line-height: 1.3;
    `,
    component: 'h4'
  },
  h5: {
    css: css`
      font-size: ${rem(18)};
      font-weight: bold;
      line-height: 1.3;
    `,
    component: 'h5'
  },
  h6: {
    css: css`
      font-size: ${rem(14)};
      font-weight: bold;
      line-height: 1.3;
    `,
    component: 'h5'
  },
  subtitle1: {
    css: css`
      font-size: ${rem(18)};
      font-weight: bold;
      line-height: 1.5;
    `,
    component: 'p'
  },
  subtitle2: {
    css: css`
      font-size: ${rem(14)};
      font-weight: bold;
      line-height: 2;
    `,
    component: 'p'
  },
  body1: {
    css: css`
      font-size: ${rem(16)};
      font-weight: 400;
      line-height: 2;
    `,
    component: 'p'
  },
  body2: {
    css: css`
      font-size: ${rem(14)};
      font-weight: 400;
      line-height: 2;
    `,
    component: 'p'
  },
  caption: {
    css: css`
      font-size: ${rem(12)};
      font-weight: 400;
      line-height: 1.5;
    `,
    component: 'p'
  },
  overline: {
    css: css`
      font-size: ${rem(10)};
      font-weight: 400;
      line-height: 1.5;
    `,
    component: 'p'
  },
  button: {
    css: css`
      font-size: ${rem(18)};
      line-height: 1.5;
      font-weight: bold;
    `,
    component: 'p'
  },
  price: {
    css: css`
      font-size: ${rem(24)};
      line-height: 1.5;
      font-weight: bold;
    `,
    component: 'p'
  }
}

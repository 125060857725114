import breakpoints from './breakpoints'
import layouts from './layouts'
import fonts from './fonts'
import easing from './easing'
import zIndex from './z-index'

export default {
  breakpoints,
  layouts,
  fonts,
  easing
}

export { breakpoints, layouts, fonts, easing, zIndex }

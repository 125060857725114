export interface Theme {
  [key: string]: {
    [key: string]:
      | string
      | {
          [key: string]: string
        }
  }
}

interface Props {
  theme: Theme
  color?: ColorParams
  [key: string]: any
}

const themes: { [key: string]: Theme } = {
  default: {
    primary: {
      '100': '#EF8D0F',
      '50': '#F7C687',
      '30': '#FADDB7',
      '20': '#FCE8CF',
      '10': '#FDF4E7',
      contrast: {
        '100': '#fff',
        '50': '#fff',
        '30': '#000',
        '20': '#000',
        '10': '#000'
      }
    },
    secondary: {
      '100': '#428cf4',
      contrast: {
        '100': '#fff'
      }
    },
    line: {
      '100': '#00C300',
      contrast: {
        '100': '#fff'
      }
    },
    black: {
      '100': '#000',
      '50': '#818181',
      '20': '#ccc',
      '10': '#e6e6e6',
      '5': '#f2f2f2',
      '2': '#fafafa',
      '0': '#fff',
      contrast: {
        '100': '#fff',
        '50': '#fff',
        '20': '#000',
        '10': '#000',
        '5': '#000',
        '2': '#000',
        '0': '#000'
      }
    }
  }
}

export default themes

export const parseThemeColor = (keyPath: string) => {
  const array = keyPath.split('.')
  const type = array[0]
  const tone = array.length > 1 ? array[1] : '100'
  return { type, tone }
}

interface Params {
  key?: string
  state?: string
}

export type ColorParams = Params | string

const extract = (params?: ColorParams, props?: Props): Params | undefined => {
  const a = params
    ? {
        key: typeof params === 'string' ? params : params?.key,
        state: typeof params === 'string' ? undefined : params?.state
      }
    : {}
  const b =
    props && props?.color
      ? {
          key: typeof props.color === 'string' ? props.color : props.color?.key,
          state:
            typeof props.color === 'string' ? undefined : props.color?.state
        }
      : {}
  return {
    key: a.key || b.key,
    state: a.state || b.state
  }
}

export const color = (params?: ColorParams) => (props: Props) => {
  const e = extract(params, props)
  if (!e) return
  const { key, state } = e
  if (!key) return
  const { type, tone } = parseThemeColor(key)
  if (!state) return props.theme[type][tone]
  if (typeof props.theme[type][state] === 'string') {
    return
  }
  if (!props.theme) return
  return (props.theme as any)[type][state][tone]
}

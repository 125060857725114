import React from 'react'
import PropTypes from 'prop-types'
import './src/iconfont'
import CSS from './src/css/Global'
import { ThemeProvider } from '@mui/material/styles'
import MUITheme from './src/utils/mui'
import InlineScripts from './src/components/head/InlineScripts'
import Default from './src/layouts/Default'

const PageWrapper = ({ element }) => {
  return (
    <React.Fragment>
      <CSS />
      <InlineScripts />
      <ThemeProvider theme={MUITheme}>
        <Default>{element}</Default>
      </ThemeProvider>
    </React.Fragment>
  )
}

PageWrapper.propTypes = {
  element: PropTypes.any
}

export const wrapPageElement = ({ element }) => (
  <PageWrapper element={element} />
)

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    const ObjectFitImages = await import(`object-fit-images`)
    ObjectFitImages.default()
  }
}

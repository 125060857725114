import React, { FC, useContext } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import gsap, { Power4 } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { mq, rem, shadeColor } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import CSSEase from '@/utils/easing'
import { Theme, color, ColorParams } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Typography, { TypographyProps } from '@/components/typography'
import _Icon from '@/components/snippets/Icon'

gsap.registerPlugin(ScrollToPlugin)

type ButtonSize = 'small' | 'default'

type Width = 'fit' | 'fill' | 'default'

type Type = 'contained' | 'outlined'

interface Props {
  id?: string
  to?: string
  textProps?: TypographyProps
  component?: any
  color?: ColorParams
  width?: Width
  size?: ButtonSize
  iconBefore?: string
  iconAfter?: string
  type?: Type
  [key: string]: any
  onClick?: (e: React.MouseEvent) => void
}

interface RootStyleProps extends Props {
  theme: Theme
}

interface IconStyleProps extends Props {
  size?: ButtonSize
  width?: Width
}

const getRootComponent = (props: Props) => {
  if (props.component) return props.component
  if (props.to) return DefaultRoot.withComponent(Link)
  if (props.href) return DefaultRoot.withComponent('a')
  return DefaultRoot
}

const onClick = (event: React.MouseEvent, path?: string) => {
  if (!path) return
  if (path.indexOf('#') === -1) {
    return
  }
  event.preventDefault()
  const anchor = event.currentTarget as HTMLAnchorElement
  const id = anchor.hash
  if (!document.querySelector(id)) {
    return
  }
  const headerHeight = document.getElementById('header')!.clientHeight
  gsap.to(window, {
    duration: 0.6,
    ease: Power4.easeInOut,
    scrollTo: {
      y: id,
      offsetY: headerHeight
    }
  })
}

const Button: FC<Props> = ({ textProps, iconBefore, iconAfter, ...props }) => {
  if (props.size === 'small' && textProps) {
    textProps.variant = 'subtitle2'
  }
  const { theme } = useContext(ThemeContext)
  const Root = getRootComponent(props)
  return (
    <Root
      {...props}
      theme={theme}
      onClick={
        props.onClick
          ? props.onClick
          : (e: React.MouseEvent) => onClick(e, props.to)
      }
    >
      {iconBefore && (
        <Icon size={props.size} width={props.width} before>
          {iconBefore}
        </Icon>
      )}
      <Text {...textProps!} theme={theme}>
        {props.children}
      </Text>
      {iconAfter && (
        <Icon size={props.size} width={props.width} after>
          {iconAfter}
        </Icon>
      )}
    </Root>
  )
}

Button.defaultProps = {
  color: 'primary',
  width: 'default',
  size: 'default',
  type: 'contained',
  textProps: {
    variant: 'button',
    component: 'span',
    responsive: {
      sm: 'subtitle2'
    }
  }
}

export default Button

const Icon = styled(_Icon)<IconStyleProps>`
  font-size: ${(props) => {
    return props.size === 'small' ? rem(16) : rem(24)
  }};
  ${(props) =>
    props.before &&
    css`
      margin-right: ${rem(12)};
    `}
  ${(props) => {
    if (!props.after) return
    if (props.width === 'fit') {
      return css`
        margin-left: ${rem(12)};
      `
    }
    return css`
      position: absolute;
      right: ${rem(16)};
      top: 50%;
      transform: translateY(-50%);
    `
  }}
`

const DefaultRoot = styled.div<RootStyleProps>`
  ${({ size }) => {
    if (size === 'small') {
      return css`
        padding: ${rem(8)} ${rem(16)};
      `
    }
    return css`
      padding: ${rem(16)};
      @media ${mq.with(breakpoints.sm)} {
        padding: ${rem(12)} ${rem(16)};
      }
    `
  }};
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ width }) => {
    switch (width) {
      case 'fit':
        return
      case 'fill':
        return css`
          width: 100%;
        `
      default:
        return css`
          width: ${rem(392)};
          @media ${mq.with(breakpoints.sm)} {
            width: 100%;
          }
        `
    }
  }};
  cursor: pointer;
  ${(props) => {
    if (props.type === 'contained') {
      return css`
        background-color: ${color()(props)};
        color: ${color({ state: 'contrast' })(props)};
        &:hover {
          background-color: ${shadeColor(color()(props), 7)};
          @media (hover: none) {
            background-color: ${color()(props)};
          }
        }
      `
    }
    return css`
      border: 2px solid ${color()(props)};
      color: ${color()(props)};
      &:hover {
        border-color: ${shadeColor(color()(props), 5)};
        color: ${shadeColor(color()(props), 10)};
        @media (hover: none) {
          border-color: ${color()(props)};
          color: ${color()(props)};
        }
      }
    `
  }};
  border-radius: ${rem(8)};
  transition: background-color 0.3s ${CSSEase.easeOtt},
    color 0.3s ${CSSEase.easeOtt};
`

const Text = styled(Typography)``

import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { color } from '@/utils/themes'
import { rem } from '@/utils/helpers'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'

interface Props {
  [key: string]: any
}

const Component: FC<Props> = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root theme={theme}>
      <Text lang="jp" variant="overline">
        © 2022 らくなげ
      </Text>
    </Root>
  )
}

const Root = styled.div`
  padding: ${rem(24)};
  background-color: ${color('primary.50')};
  color: ${color({ key: 'primary.50', state: 'contrast' })};
`

const Text = styled(Typography)`
  text-align: center;
  font-weight: bold;
`

export default Component

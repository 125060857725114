import { rem } from './helpers'

type Layouts<K extends keyof any, T> = {
  [P in K]: T
}

const layouts: Layouts<any, any> = {
  pad: {
    default: rem(64),
    xxl: rem(64),
    xl: rem(64),
    gt_lg: rem(64),
    lg: rem(48),
    gt_md: rem(48),
    md: rem(48),
    gt_sm: rem(48),
    sm: rem(20),
    xs: rem(20)
  },
  headerHeight: {
    default: rem(76),
    lte_md: rem(48)
  },
  width: {
    default: {
      1: rem(1224),
      2: rem(1016),
      3: rem(806)
    },
    xxl: {
      1: rem(1224),
      2: rem(1016),
      3: rem(806)
    },
    xl: {
      1: rem(1224),
      2: rem(1016),
      3: rem(806)
    },
    gt_lg: {
      1: rem(1224),
      2: rem(1016),
      3: rem(806)
    },
    lg: {
      1: rem(1224),
      2: rem(1016),
      3: rem(806)
    },
    gt_md: {
      1: rem(1224),
      2: rem(1016),
      3: rem(806)
    },
    md: {
      1: '100%',
      2: rem(640),
      3: rem(480)
    },
    gt_sm: {
      1: rem(1224),
      2: rem(1016),
      3: rem(806)
    },
    sm: {
      1: '100%',
      2: rem(600),
      3: rem(600)
    },
    xs: {
      1: '100%',
      2: '100%',
      3: '100%'
    }
  }
}

export default layouts

import React from 'react'
import Helmet from 'react-helmet'

const InlineScripts = () => {
  return (
    <Helmet>
      <script type="application/javascript">{`
        const getWindowHeight = () => {
          const axis = Math.abs(window.orientation);
          const dims = { w: 0, h: 0 };
          let ruler = document.createElement('div');
          ruler.style.position = 'fixed';
          ruler.style.height = '100vh';
          ruler.style.width = 0;
          ruler.style.top = 0;
          document.documentElement.appendChild(ruler);
          dims.w = axis === 90 ? ruler.offsetHeight : window.innerWidth;
          dims.h = axis === 90 ? window.innerWidth : ruler.offsetHeight;
          document.documentElement.removeChild(ruler);
          ruler = null;
          if (Math.abs(window.orientation) !== 90) {
            return dims.h;
          }
          return dims.w;
        }
        window.addEventListener('resize', function () {
          const windowHeight = getWindowHeight();
          const vh = windowHeight * 0.01;
          const header = document.getElementById('header');
          const headerHeight = header ? header.clientHeight: 0;
          document.documentElement.style.setProperty('--vh', vh + 'px');
          document.documentElement.style.setProperty('--headerHeight', headerHeight + 'px');
        });
        const windowHeight = getWindowHeight();
        const vh = windowHeight * 0.01;
        const header = document.getElementById('header');
        const headerHeight = header ? header.clientHeight: 0;
        document.documentElement.style.setProperty('--vh', vh + 'px');
        document.documentElement.style.setProperty('--headerHeight', headerHeight + 'px');
      `}</script>
    </Helmet>
  )
}

export default InlineScripts

import React, { Fragment } from 'react'
import Initialize from '@/css/Global/initialize'
import Visible from '@/css/Global/visible'

const CSS = () => (
  <Fragment>
    <Initialize />
    <Visible />
  </Fragment>
)
export default CSS

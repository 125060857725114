import React from 'react'
import themes, { Theme } from '@/utils/themes'

export type ThemeContextType = {
  theme: Theme
  setTheme: (theme: Theme) => void
}

export default React.createContext<ThemeContextType>({
  theme: themes.default,
  setTheme: () => {}
})

import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#EF8D0F'
    },
    secondary: {
      main: '#428cf4'
    },
    error: {
      main: red.A400
    }
  }
})

export default theme
